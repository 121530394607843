import { render, staticRenderFns } from "./XmUploadDia.vue?vue&type=template&id=7b871dcc&scoped=true"
import script from "./XmUploadDia.vue?vue&type=script&lang=js"
export * from "./XmUploadDia.vue?vue&type=script&lang=js"
import style0 from "./XmUploadDia.vue?vue&type=style&index=0&id=7b871dcc&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b871dcc",
  null
  
)

export default component.exports