<template>
  <div class="configmodel">
    <el-dialog
      :title="title"
      :lock-scroll="false"
      :close-on-click-modal="false"
      :visible.sync="deleteDialogVisible"
      :width="width"
      :before-close="closeDeletedig"
    >
      <div>
        <div class="content">
          <div class="imgmodel" v-if="hasImgmodel">
            <img v-if="type == 'red'" src="@/assets/images/redtip.svg" />
            <img v-if="type == 'yellow'" src="@/assets/images/yeTip.svg" />
          </div>
          <div class="text">
            {{ content }}
          </div>
        </div>
        <slot></slot>
        <div class="btnmodel">
          <div class="confirm btn" @click="configDelete()">
            {{ mainBtnTitle }}
          </div>
          <div class="cancel btn" v-if="btnTitle" @click="closeDeletedig">
            {{ btnTitle }}
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'XimuDialog',
  props: {
    // 弹窗标题
    title: {
      type: String,
      default: '提示',
    },
    content: {
      type: String,
      default: '确定要删除吗？',
    },
    deleteDialogVisible: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'red',
    },
    // 控制提示图标是否显示
    hasImgmodel: {
      type: Boolean,
      default: true,
    },
    // 弹窗宽度
    width: {
      type: String,
      default: '480px',
    },
    // 主要按钮
    mainBtnTitle: {
      type: String,
      default: '确定',
    },
    btnTitle: {
      type: String,
      default: '取消',
    },
  },
  data() {
    return {};
  },
  methods: {
    closeDeletedig() {
      // this.deleteDialogVisible = false;
      this.$emit('closeDeletedig'); // 父组件绑定的事件
    },
    configDelete() {
      // this.deleteDialogVisible = false;
      this.$emit('configDeletedig'); // 父组件绑定的事件
    },
  },
};
</script>

<style lang="scss" scoped>
.configmodel ::v-deep .el-dialog {
  margin: 0px !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.configmodel ::v-deep .el-dialog__header {
  height: 60px;
  background: #f2f2f2;
  padding: 20px;
  box-sizing: border-box;
}

.configmodel ::v-deep .el-dialog__title {
  font-size: 16px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #111111;
}

.configmodel ::v-deep .el-dialog__body {
  padding: 20px 20px 30px 20px;
}

.content {
  display: flex;
  justify-content: left;
}

.imgmodel {
  flex-shrink: 0;
  width: 14px;
  height: 14px;
  margin-right: 4px;
  img {
    width: 100%;
    height: 100%;
    vertical-align: middle;
  }
}

.text {
  font-size: 14px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  line-height: 22px;
}

.btnmodel {
  margin-top: 20px;
  display: flex;
  justify-content: right;
  .btn {
    width: 60px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 14px;
    cursor: pointer;
  }
  .confirm {
    color: #ffffff;
    background: #ff7802;
  }
  .cancel {
    margin-left: 20px;
    color: #606266;
    background: #ffffff;
    border: 1px solid #cccccc;
  }
}

.configmodel ::v-deep.el-dialog__headerbtn:focus .el-dialog__close,
.el-dialog__headerbtn:hover .el-dialog__close {
  color: #909399 !important;
}
.configmodel ::v-deep .el-dialog__headerbtn:hover .el-dialog__close {
  color: #909399 !important;
}
.configmodel ::v-deep .el-dialog__headerbtn .el-dialog__close {
  font-size: 24px;
}
</style>
