import request from '@/utils/request.js';

// 发送验证码
export function sendSmsCode(data) {
  return request({
    url: '/Sms/sendSmsCode',
    method: 'get',
    params: data,
  });
}

// 获取文件导出历史
export function exportTaskList(data) {
  return request({
    url: '/exportTask/exportTaskList',
    method: 'post',
    data: data,
  });
}

// 获取资金模块文件导出历史
export function exportFundTaskList(data) {
  return request({
    url: '/exportTask/exportTaskList',
    apiModule: 'fund',
    method: 'get',
    params: data,
  });
}
