<template>
  <div>
    <xm-dialog title="导出历史" width="651px" @close-dialog="handleClose">
      <div class="content">
        <xm-table
          :isCheckbox="false"
          :tableFiled="tableConfig"
          :table="exportList"
        >
          <div slot="operaSlot" slot-scope="{ row }">
            <span v-if="row.row.status_name == '已完成'">
              <a :href="row.row.url">文件下载</a>
            </span>
          </div>
        </xm-table>
        <div class="page-model">
          <pagToolsDialog
            :pagesize="pageSize"
            :pages="pages"
            :total="total"
            @go-pages="goPage"
          ></pagToolsDialog>
        </div>
      </div>
    </xm-dialog>
  </div>
</template>
<script>
import { exportTaskList, exportFundTaskList } from '@/api/public_port.js';
export default {
  props: {
    // 这是后端决定的
    type: {
      type: [Number, String],
      default: 2,
    },
    // 每页大小
    pageSize: {
      type: Number,
      default: 12,
    },
    // 模块名称
    moduleType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      exportList: [], // 导出列表
      tableConfig: [
        {
          text: '任务创建时间',
          filed: 'creation_time',
          width: '171px',
          isSort: false,
        },
        {
          text: '任务ID',
          filed: 'task_id',
          width: '148px',
          isSort: false,
        },
        {
          text: '导出申请人',
          filed: 'export_applicant',
          width: '102px',
          isSort: false,
        },
        {
          text: '任务状态',
          filed: 'status_name',
          width: '102px',
          isSort: false,
        },
        {
          text: '操作',
          filed: 'opera',
          width: '88px',
          isSort: false,
          isEdit: true,
        },
      ],
      total: 0, // 总数
      pages: 1, // 当前页码
    };
  },
  methods: {
    /**
     * 页面触发
     */

    // 切换页码
    goPage(page) {
      this.pages = Number(page) || 1;
      this.fetchExportedHistory();
    },
    // 关闭弹窗
    handleClose() {
      this.$emit('close');
    },

    /**
     * 请求接口
     */
    // 获取导出历史列表
    async fetchExportedHistory() {
      try {
        let options = {
          type: this.type,
          pageSize: this.pageSize,
          pageNum: this.pages,
          MUTE_WARNING: 1,
        };
        // 获取资金模块导出列表 资金模块的导出请求方式和模块api不同
        let res = null;
        if (this.moduleType === 'fund') {
          res = await exportFundTaskList(options);
        } else {
          res = await exportTaskList(options);
        }
        if (res?.errcode == 0) {
          this.exportList = res.data?.list || [];
          this.total = Number(res.data?.total) || 0;
        }
      } catch (err) {
        console.error(err);
      }
    },
  },
  mounted() {
    this.fetchExportedHistory();
  },
};
</script>
<style scoped lang="scss">
.content {
  width: 651px;
  box-sizing: border-box;
  padding: 20px;
  .page-model {
    display: flex;
    justify-content: center;
    margin: 25px auto 10px auto;
  }

  a {
    text-decoration: none;
    color: #ff7802;
  }

  ::v-deep .el-table {
    th.el-table__cell.is-leaf,
    td.el-table__cell {
      box-shadow: inset 0px -1px 0px 0px #ebeef5;
    }
    td.el-table__cell {
      font-size: 14px;
      color: #333;
      line-height: 22px;

      box-shadow: inset 0px -1px 0px 0px #ebeef5;

      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
</style>
