<template>
  <!-- 带input的下拉选择器组件 -->
  <div class="xm-select-input">
    <div class="select_box">
      <el-select
        clearable
        v-model="selectVaule"
        :placeholder="placeholder"
        :popper-append-to-body="appendBody"
        @change="onChange"
        @clear="clearVal"
        :style="{ width: width }"
      >
        <el-option
          v-for="item in optionData"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>
    <div :style="{ width: inputWidth }" class="input_box">
      <el-input
        class="search-input"
        v-model="inputValue"
        size="small"
        :placeholder="inputPlaceholder"
        @keyup.enter.native="onBtn"
        clearable
        @clear="onBtn"
      >
        <i slot="suffix" class="iconfont search-icon" @click="onBtn">
          <img src="@/assets/images/search.svg"
        /></i>
      </el-input>
    </div>
  </div>
</template>

<script>
export default {
  name: 'XmSelect',
  data() {
    return {
      selectVaule: '', // 选项的val
      inputValue: '', // input的val值
    };
  },
  props: {
    width: {
      // 选择器的宽度
      type: String,
      default: '112px',
    },
    inputWidth: {
      // input的宽度
      type: String,
      default: '157px',
    },
    optionData: {
      // 选项数据
      type: Array,
      default: () => [],
    },
    // placeholder: {
    //   // 下拉选择的placeholder
    //   type: String,
    //   default: '请选择',
    // },
    inputPlaceholder: {
      // input框的placehoder
      type: String,
      default: '请输入',
    },
    appendBody: {
      type: Boolean,
      default: false,
    },
    isSearch: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    placeholder() {
      return this.optionData.length > 0 ? this.optionData[0].label : '';
    },
  },
  methods: {
    // 放大镜点击事件
    onBtn() {
      if (this.selectVaule == '') {
        this.selectVaule = this.optionData[0].value;
      }
      this.onSearch();
    },
    // 传值出去的方法
    onSearch() {
      this.$emit('change', this.selectVaule, this.inputValue);
    },
    // 切换下拉框值的方法
    onChange() {
      this.inputValue = '';
      this.$emit('change-prop', this.selectVaule, this.inputValue);
    },
    // 点击清空按钮的方法
    clearVal() {
      this.inputValue = '';
      this.onSearch();
    },
    reVal(selectVaule = '', inputValue = '') {
      this.selectVaule = selectVaule;
      this.inputValue = inputValue;
      if (this.isSearch) {
        this.onSearch();
      }
    },
    // 设置下拉框的值，单纯设置，不包含其他操作
    setValue(selectVaule = '', inputValue = '') {
      this.selectVaule = selectVaule;
      this.inputValue = inputValue;
    },
  },
};
</script>

<style lang="scss" scoped>
.xm-select-input {
  display: flex;
  .select_box {
    ::v-deep .el-input__inner {
      border-radius: 4px 0 0 4px;
      padding-right: 39px;
    }
  }
  .input_box {
    ::v-deep .el-input__inner {
      border-radius: 0 4px 4px 0;
    }
  }
  .search-input {
    ::v-deep .el-input__inner {
      border-left: 0px;
      padding-right: 42px;
    }
    ::v-deep .el-input__inner:hover {
      border-left-color: #c0c4cc;
    }
    ::v-deep .el-input__inner:focus {
      border-left: 1px solid #026bff;
    }

    ::v-deep .el-input__clear {
      position: absolute;
      right: -4px;
      top: 50%;
      transform: translateY(-50%);
    }

    ::v-deep .el-input__suffix {
      right: 0;

      .search-icon {
        img {
          position: absolute;
          top: 50%;
          right: 24px;
          transform: translateY(-50%);
        }
      }
    }
  }

  ::v-deep .el-input__inner {
    height: 40px;
    border-radius: 0px;
    padding-right: 40px;
    color: #808080;
  }

  ::v-deep .el-select-dropdown__item {
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
  }
  ::v-deep .el-select-dropdown__item:hover,
  .el-select-dropdown__item.hover {
    background-color: #fff2e6 !important;
  }

  ::v-deep .el-select-dropdown__item.selected {
    font-weight: 400 !important;
  }
  .pointer {
    cursor: pointer;
  }
}
</style>
