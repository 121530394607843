<!-- 分页 -->
<template>
  <div class="goods_el_table_pagination">
    <div
      class="goods_pagination_box"
      :class="currentpage1 == curPages ? 'activeColor' : ''"
      v-if="total > 0"
    >
      <div
        class="btn_style"
        @click="goPage(-1)"
        :class="curPages == 1 ? 'prohibited' : ''"
      >
        <svg
          t="1680268631475"
          class="icon arrowsIcon spinBtn"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="2985"
          width="16"
          height="16"
        >
          <path
            d="M670.208 481.792a42.666667 42.666667 0 0 1 0 60.416l-256 256a42.666667 42.666667 0 0 1-60.416-60.416L579.669333 512 353.792 286.208a42.666667 42.666667 0 0 1 60.416-60.416l256 256z"
            fill="#999999"
            p-id="2986"
          ></path>
        </svg>
        上一页
      </div>
      <el-pagination
        @current-change="currentChange"
        :current-page="curPages"
        background
        :pager-count="5"
        layout=" pager"
        :prev-text="'上一页'"
        :next-text="'下一页'"
        :total="total"
        :page-size="curPageSize"
      ></el-pagination>
      <div
        class="btn_style"
        @click="goPage(1)"
        :class="curPages == totalPageNum() ? 'prohibited' : ''"
      >
        下一页
        <svg
          t="1680268631475"
          class="icon arrowsIcon"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="2985"
          width="16"
          height="16"
        >
          <path
            d="M670.208 481.792a42.666667 42.666667 0 0 1 0 60.416l-256 256a42.666667 42.666667 0 0 1-60.416-60.416L579.669333 512 353.792 286.208a42.666667 42.666667 0 0 1 60.416-60.416l256 256z"
            fill="#999999"
            p-id="2986"
          ></path>
        </svg>
      </div>
      <div
        class="page-action"
        v-if="hasTotal"
        :class="{ 'chose-pagesize': isPageSize }"
      >
        <div class="page_all">共{{ totalPageNum() }}页，</div>
        <div class="page_style">
          <template v-if="isPageSize"
            >每页显示
            <div class="pagesize-box">
              <el-select
                v-model="curPageSize"
                size="small"
                @change="changPageSize"
              >
                <el-option
                  v-for="item in pageSizeOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </template>
          跳转到
        </div>
        <input
          type="text"
          class="paginationInput"
          v-model="currentpage"
          @keyup.enter="goPageBtn"
        />
        <div class="page_style">页</div>
        <button class="paginationBtn" @click="goPageBtn">确定</button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'pagTools',
  data() {
    return {
      // total: 100,
      // pages: 1,
      // pageSize: 10,
      curPages: this.pages, // 页数
      currentpage1: 1,

      currentpage: '', // 输入框输入想要跳转到的页面
      curPageSize: this.pageSize, // 每页展示几条数据
      allPage: '', //总页数
      // 每页展示几条，条数控制
      pageSizeOptions: [
        { value: 50, label: '50' },
        { value: 40, label: '40' },
        { value: 30, label: '30' },
        { value: 20, label: '20' },
        { value: 10, label: '10' },
      ],
    };
  },
  props: {
    total: {
      type: Number,
      default: 0,
    },
    // pageSize:number,
    pageSize: {
      type: Number,
      default: 10,
    },
    pages: {
      type: Number,
      default: 1,
    },
    // 是否有总页码、跳转按钮
    hasTotal: {
      type: Boolean,
      default: true,
    },
    // 控制是否需要自定义每页条数展示
    isPageSize: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    currentpage: function (n) {
      if (!n) {
        return;
      }
      if (Number(n) < this.allPage) {
        this.currentpage = Number(n);
      } else {
        this.currentpage = this.allPage;
      }
    },
    pages: function (page) {
      this.curPages = page;
      if (page === 1) {
        this.currentpage = '';
      }
    },
    // 监听pageSize，例如切换布局pageSize不一样时，更新curPageSize的值。
    pageSize(pageSize) {
      this.curPageSize = pageSize;
    },
  },
  methods: {
    // 总页数
    totalPageNum() {
      if (this.total == null || this.total == '') {
        return 0;
      } else {
        if (this.curPageSize != 0 && this.total % this.curPageSize == 0) {
          this.allPage = parseInt(this.total / this.curPageSize);
          return parseInt(this.total / this.curPageSize);
        }
        if (this.curPageSize != 0 && this.total % this.curPageSize != 0) {
          this.allPage = parseInt(this.total / this.curPageSize) + 1;
          return parseInt(this.total / this.curPageSize) + 1;
        }
      }
    },
    // 改变每页展示的条数
    changPageSize() {
      // 当前页数大于总页数时，让当前页数等于总页数
      if (this.curPages > this.totalPageNum()) {
        this.curPages = this.allPage;
      }
      this.$emit('change-pagesize', this.curPages, this.curPageSize);
    },
    // 输入想要跳转的页数，点击确定
    goPageBtn() {
      if (!this.currentpage) {
        this.$message({
          type: 'warning',
          message: '请选择要跳转的页码',
        });
        return;
      }
      this.curPages = Number(this.currentpage);
      this.currentpage1 = this.curPages;
      this.$emit('goPageBtn', this.curPages);
    },
    currentChange(n) {
      this.curPages = n;
      this.currentpage1 = this.curPages;
      this.$emit('currentChange', n);
    },
    goPage(n) {
      if (this.total === 0) {
        return;
      }
      this.curPages += n;
      this.currentpage1 = this.curPages;
      this.$emit('goPageNum', this.curPages, this.curPageSize);
    },
    // 回到初始页面 加ref调用该方法
    changecurPage(n = 1) {
      this.curPages = n;
    },
  },
};
</script>
<style lang="scss" scoped>
.goods_el_table_pagination {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  * {
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
  }

  span {
    font-size: 12px;
    font-weight: 400;
    color: #999999;
    margin-right: auto;
  }

  .goods_pagination_box {
    display: flex;
    align-items: center;

    span {
      width: 40px;
      height: 28px;
      background: #ffffff;
      border-radius: 4px;
      border: 1px solid #eeeeee;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      font-size: 12px;
      font-weight: 400;
      color: #333333;
    }
  }
}

.page-action {
  display: flex;
  align-items: center;
  &.chose-pagesize .paginationInput {
    padding: 0 10px;
    width: 74px;
    height: 32px;
    color: #808080;
    font-size: 14px;
    border: 1px solid #808080;
  }
}
.paginationInput {
  width: 32px;
  height: 32px;
  text-align: center;
  outline: none;
  border: 1px solid #ccc;
  margin: 0 10px;
  height: 28px;
  border-radius: 4px 4px 4px 4px;
}
.paginationBtn {
  width: 48px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #eeeeee;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  color: #808080;
  margin-left: 10px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
}

.goods_pagination_box ::v-deep .el-pagination.is-background .el-pager li {
  width: 32px;
  height: 32px;
  background: #ffffff !important;
  box-shadow: 4px 8px 16px 0px rgba(0, 0, 0, 0.1);
  border-radius: 4px 4px 4px 4px;
  opacity: 0.8;
  font-size: 14px;
  line-height: 32px;
}
.goods_pagination_box
  ::v-deep
  .el-pagination.is-background
  .el-pager
  li:not(.disabled).active {
  background-color: #ff7802 !important;
}
/* .goods_pagination_box ::v-deep .btn-prev{
    width: 78px;
    height: 32px;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.15);
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
  } */
.goods_pagination_box ::v-deep .btn-prev span {
  border-radius: 4px 4px 4px 4px;
}
/* .goods_pagination_box ::v-deep .btn-next{
    width: 78px;
    height: 32px;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.15);
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
  } */
.btn_style {
  width: 78px;
  height: 32px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  text-align: center;
  /* line-height: 32px; */
  cursor: pointer;
  -moz-user-select: none; /*moziLar*/
  -webkit-user-select: none; /*webkit*/
  -ms-user-select: none; /*IE*/
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #808080;
}
.page_all {
  margin-left: 20px;
  font-size: 14px;
  color: #111111;
}
.page_style {
  font-size: 14px;
  color: #111111;
  display: flex;
  align-items: center;

  .pagesize-box {
    width: 59px;
    margin: 0 10px;
    border-radius: 20px;
    ::v-deep .el-input__inner {
      border-radius: 4px;
      border-color: #808080;
      padding: 0 8px 0 15px;
      height: 32px !important;
    }
  }
}
.prohibited {
  pointer-events: none;
}
.arrowsIcon {
  vertical-align: middle;
}
.spinBtn {
  transform: rotate(180deg);
}
</style>
