<template>
  <div class="ximu-dialog">
    <!-- 遮罩层 -->
    <div class="mask" :style="{ opacity: opacity, 'z-index': zIndex }"></div>
    <!-- 弹窗主体 -->
    <div class="box" :style="{ width: width, 'z-index': Number(zIndex) + 1 }">
      <div class="box-header">
        <div class="box-header-title">{{ title }}</div>
        <i @click="close" class="box-close-icon el-icon-close"></i>
      </div>

      <div class="box-main">
        <!-- tabs -->
        <!-- <div class="tab"></div> -->
        <!-- 提示 -->
        <div class="ximu-dialog-tip" v-if="imgSuccess && imgSuccess.length > 0">
          <div class="ximu-dialog-tip-text">
            上传和选择的图片会添加在这里，您可以对一下图片进行拖拽来调整图片顺序
          </div>
          <div class="ximu-dialog-tip-num">
            已选：<span
              ><span class="ximu-dialog-tip-strong">{{
                imgSuccess.length
              }}</span
              >/{{ imgNum }}</span
            >
          </div>
        </div>
        <!-- 图片 -->
        <XimuPicExchange
          class="ximu-dialog-tip-img"
          :picArray="imgList"
          @change-pic="changePic"
          @del-pic="delPic"
          v-if="imgSuccess && imgSuccess.length > 0"
        ></XimuPicExchange>
        <!-- 提示1 -->
        <div class="ximu-dialog-num-tip">
          <span
            >上传<span class="strong">{{ allImgList.length }}</span
            >张</span
          >，
          <span
            >成功<span class="strong">{{ imgSuccess.length }}</span
            >张</span
          >，
          <span
            >失败<span class="strong">{{ imgErrorList.length }}</span
            >张</span
          >
        </div>

        <!-- 内容 -->
        <div class="img-box" v-show="isShow">
          <el-upload
            class="upload-demo"
            drag
            action="/supplierapi/Material/addMaterial"
            :data="{ token }"
            multiple
            :accept="accept"
            :show-file-list="false"
            :before-upload="beforeUpload"
            :on-success="onSuccess"
            :on-progress="onProgress"
            :on-change="onChange"
            :before-remove="beforeRemove"
            :on-error="onError"
          >
            <i class="el-icon-upload margin-top30"></i>
            <div class="el-upload__text">
              将文件拖到此处，或<em>点击上传</em>
            </div>
            <div class="XmUploadDia-box">
              <div class="XmUploadDia-tip">
                仅支持{{ format }}格式,大小必须小于5.0MB
              </div>
              <div class="XmUploadDia-tip">
                支持图片批量上传，最多支持{{ imgNum }}张图片
              </div>
            </div>
          </el-upload>
        </div>
        <!-- 表格 -->
        <div class="table-box" v-show="!isShow">
          <el-table
            :header-cell-style="headerStyle"
            :data="imgList"
            style="width: 99%"
            height="336"
            border
          >
            <el-table-column prop="name" label="图片名称"> </el-table-column>
            <el-table-column prop="imGsize" label="图片尺寸" width="150px">
              <!-- <template slot-scope="{ row }">
                {{ row.width }}x{{ row.height }}
              </template> -->
            </el-table-column>
            <el-table-column prop="size" label="图片大小" width="150px">
            </el-table-column>
            <el-table-column prop="status" label="状态">
              <template slot-scope="{ row }">
                <el-progress
                  :percentage="row.percentage"
                  :status="row.status"
                  v-if="row.isShow"
                ></el-progress>
                <div v-if="!row.isShow">{{ row.status }}</div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="ximu-dialog-btn">
          <el-button
            class="ximu-dialog-style ximu-dialog-confirm"
            @click="upImgconfim"
            :disabled="isdis"
          >
            确认上传
          </el-button>
          <div
            class="ximu-dialog-style ximu-dialog-other"
            v-show="!isShow"
            @click="keepUp"
          >
            继续上传
          </div>
          <div
            class="ximu-dialog-style ximu-dialog-other"
            v-show="isShow"
            @click="close"
          >
            取消
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import XimuPicExchange from '@/components/XimuComponents/XimuPicExchange.vue';
export default {
  components: { XimuPicExchange },
  name: 'XimuDialog',
  props: {
    title: {
      type: String,
      default: '上传图片',
    },
    width: {
      // 弹窗宽度
      type: String,
      default: '910px',
    },
    opacity: {
      // 遮罩层的透明度
      type: String,
      default: '0.25',
    },
    zIndex: {
      // 遮罩层的z轴坐标
      type: String,
      default: '2000',
    },
    // 限制类型
    format: {
      type: String,
      default: 'jpg,png,jpeg',
    },
    // 限制的图片数量
    // imgNum: {
    //   type: Number,
    //   default: 6,
    // },
    /**
     * 限制能上传的文件格式
     * 默认只能传png,jpeg,jpg图片
     * eg: '.png, .jpeg, .jpg, .mp4'
     */
    accept: {
      type: String,
      default: '.png, .jpeg, .jpg, .PNG, .JPEG, .JPG',
    },
    // 限制上传的大小
    maxSize: {
      type: Number,
      default: 5,
    },
    // 限制上传的图片最大宽度
    maxWidth: {
      type: [String, Number],
      default: '',
    },
  },
  data() {
    return {
      imgList: [], //表格列表
      imgSuccess: [], //成功上传的图片列表
      imgErrorList: [], //上传失败的图片列表
      fileList: [], //上传图片列表
      allImgList: [],
      isShow: true,
      token: localStorage.getItem('token'),
      uploadedCount: 0, //限制上传数量
      imgNum: 200, //限制数量
      isdis: false,
      orderList: [], // 图片的上传顺序
      timmer: null,
    };
  },
  methods: {
    // 继续上传
    keepUp() {
      this.isShow = true;
    },
    // 上传
    /**
     * 上传前
     * 过滤不可上传的文件类型 props: accept
     */
    beforeUpload(file) {
      // 自定义限制的可上传的文件格式字符串（后缀们）
      let fileNameLength = file.name.split('.').length;
      let limitType = this.accept.replace(/ /g, '').replace(/\./g, '');
      if (!limitType.includes(file.name.split('.')[fileNameLength - 1])) {
        this.$message.warning('请上传' + limitType + '格式文件');
        return false;
      }

      const options = {};
      this.isShow = false;
      if (this.uploadedCount >= this.imgNum) {
        // 已上传的文件数量达到imgNum个，不继续上传
        return false;
      }
      this.uploadedCount++;
      // 获取图片尺寸信息，可以使用第三方库如exif.js（需单独引入）
      const image = new Image();
      image.src = window.URL.createObjectURL(file);
      image.onload = () => {
        let width = image.naturalWidth;
        let height = image.naturalHeight;
        if (this.maxWidth) {
          if (width > this.maxWidth) {
            height *= this.maxWidth / width;
            height = Math.floor(height);
            width = image.width = this.maxWidth;
            image.height = 'auto';
          }
        }
        const fileInfo = {
          name: file.name,
          imGsize: `${width} x ${height}`,
          width,
          height,
          uid: file.uid,
          size: this.formatFileSize(file.size),
          percentage: 0,
          status: null,
          isShow: true,
          isImg: false,
          // ...options,
        };
        // 添加文件到表格数据中

        this.imgList.push(fileInfo);
      };

      return true;
    },
    // 上传成功钩子
    onSuccess(response, file, fileList) {
      // 更新上传状态为成功
      // 上传成功
      console.log(this.orderList, '图片顺序');
      if (file.response.errcode === 0) {
        let arr = [];
        for (let i = 0; i < this.orderList.length; i++) {
          let imgItem = [];
          imgItem = this.imgList.filter(item => {
            return item.uid == this.orderList[i].uid;
          });
          if (imgItem.length > 0) {
            arr.push(imgItem[0]);
          }
        }
        if (arr.length > 0) {
          this.imgList = arr;
        }
        const index = this.imgList.findIndex(item => item.uid === file.uid);
        if (index !== -1) {
          this.imgList[index].status = '上传成功';
          this.imgList[index].isShow = false;
          this.imgList[index].isImg = true;
          this.imgList[index].img = file.response.data.link;
          // 上传成功的操作
          // this.imgSuccess.push(this.imgList[index]);
        }
      }
      if (file.response.errcode === 110) {
        const index = this.imgList.findIndex(item => item.uid === file.uid);
        if (index !== -1) {
          this.imgList[index].status = '上传失败';
          this.imgList[index].isShow = false;
          this.imgList[index].isImg = false;
          this.uploadedCount--;
          // 上传失败的操作
          this.imgErrorList.push(this.imgList[index]);
        }
      }
      if (file.response.errcode === 111) {
        const index = this.imgList.findIndex(item => item.uid === file.uid);
        if (index !== -1) {
          this.imgList[index].status = file.response.msg;
          this.imgList[index].isShow = false;
          this.imgList[index].isImg = false;
          this.uploadedCount--;
          // 上传失败的操作
          this.imgErrorList.push(this.imgList[index]);
        }
      }
      this.imgSuccess = [];
      this.imgList?.forEach(item => {
        if (item.status === '上传成功') {
          this.imgSuccess.push(item);
        }
      });
      this.allImgList = [...this.imgErrorList, ...this.imgSuccess];
      this.isdis = false;
    },
    // 上传时
    onProgress(event, file, fileList) {
      // 更新进度条的值
      const progress = Math.round((event.loaded / event.total) * 100);
      const index = this.imgList.findIndex(item => item.uid === file.uid);
      if (index !== -1) {
        this.imgList[index].percentage = progress;
      }
      this.isdis = true;
    },
    // 上传失败
    onError(err, file, fileList) {
      const maxSize = this.maxSize * 1024 * 1024;
      const index = this.imgList.findIndex(item => item.uid === file.uid);
      if (index !== -1) {
        this.imgList[index].isShow = false;
        this.imgList[index].status = '上传失败';
        if (file.size > maxSize) {
          this.imgList[index].status = '文件大于' + this.maxSize + 'M了';
        }
        this.imgErrorList.push(this.imgList[index]);
      }
    },
    // 状态改变触发的钩子
    onChange(file, fileList) {
      this.debounce(() => {
        this.orderList = fileList; // 文件列表赋值
        this.timmer = null;
      }, 1000);
    },
    debounce(func, delay) {
      return (() => {
        if (this.timmer) {
          clearTimeout(this.timmer);
        }
        this.timmer = setTimeout(func, delay);
      })();
    },
    beforeRemove(file, fileList) {},

    // 上传成功的图片对调
    changePic(list) {
      this.imgSuccess = [];
      this.imgList = list;
      for (let i = 0; i < this.imgList.length; i++) {
        if (this.imgList[i].status === '上传成功') {
          this.imgSuccess.push(this.imgList[i]);
        }
      }
      console.log(this.imgSuccess, 7777777777);
    },
    // 删除图片
    delPic(list) {
      let arr = list.filter(item => item.isImg === true);
      this.imgSuccess = arr;
      this.uploadedCount = arr.length;
      this.imgList = list;
      this.allImgList = [...this.imgErrorList, ...this.imgSuccess];
    },
    // 图片大小展示
    formatFileSize(size) {
      // 实现文件大小格式化的逻辑，如转换为KB、MB等
      if (size === 0) return '0 Bytes';
      const k = 1024;
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
      const i = Math.floor(Math.log(size) / Math.log(k));
      return parseFloat((size / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
    },

    // 成功上传的图片传出去
    upImgconfim() {
      this.$emit('upImgconfim', this.imgSuccess);
    },
    // 关闭弹框
    close() {
      this.$emit('close-dialog'); // 父组件绑定的事件
      this.reList();
    },
    reList() {
      this.imgList = [];
      this.imgSuccess = [];
      this.imgErrorList = [];
      this.fileList = [];
      this.allImgList = [];
      this.uploadedCount = 0;
      this.isShow = true;
    },
    reNum(n) {
      this.imgNum = n;
    },
  },
};
</script>

<style lang="scss" scoped>
/* 遮罩层 */
.mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #000;
}

/* 弹窗 */
.box {
  height: auto;

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2000;
  background-color: #fff;
  border-radius: 0px 0px 0px 0px;
  display: grid;
  grid-template-rows: 60px 1fr;

  // 弹窗头部标题行
  .box-header {
    height: 60px;
    background-color: #f2f2f2;

    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: space-around;
    align-items: center;

    font-size: 16px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #111111;

    padding: 20px;

    .box-close-icon {
      justify-self: end;
      color: #808080;
      font-size: 24px;
      cursor: pointer;
    }
  }
  // 弹窗主体，插槽
  .box-main {
    align-self: center;
    // padding: 20px 20px 30px 20px;

    width: 100%;
    padding: 20px;
    max-height: 90vh;
    overflow: auto;

    // 解决el-select 下拉框在弹窗组件上不显示问题
    ::v-deep .el-select .el-select-dropdown {
      position: absolute !important;
      top: calc(100%) !important;
      left: 0px !important;
    }

    .img-box {
      .upload-demo {
        ::v-deep .el-upload-dragger {
          width: 860px;
          height: 360px;
        }

        .margin-top30 {
          margin-top: 80px;
        }
      }
    }
  }
}
.XmUploadDia-box {
  font-size: 12px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #808080;
}
.XmUploadDia-tip {
  margin-top: 10px;
}
.ximu-dialog-btn {
  display: flex;
  justify-content: center;
  margin-top: 10px;

  .ximu-dialog-style {
    padding: 8px 16px;
    margin-right: 10px;
    cursor: pointer;
    height: 40px;
  }
  .ximu-dialog-confirm {
    background: #ff7802;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
  }
  .ximu-dialog-other {
    border: 1px solid #cccccc;
    box-sizing: border-box;
    background: #ffffff;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #808080;
    border-radius: 4px;
    line-height: 20px;
  }
}
.ximu-dialog-tip {
  display: flex;
  margin-bottom: 10px;
  justify-content: space-between;
  &-text {
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #666666;
  }
  &-num {
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #666666;
  }
  &-strong {
    font-weight: bold;
  }
}
.ximu-dialog-tip-img {
  margin-bottom: 10px;
  max-height: 270px;
  overflow: auto;
}
.ximu-dialog-num-tip {
  margin-bottom: 10px;
  font-size: 12px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  .strong {
    color: red;
  }
}
</style>
