<template>
  <div class="input-model">
    <el-input
      :value="value"
      @input="inputChange($event)"
      :placeholder="placeholder"
    >
      <span :slot="slotSite" class="searchicon"> {{ type }} </span>
    </el-input>
  </div>
</template>

<script>
export default {
  props: {
    value: String | Number,
    type: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '请输入',
    },
    // 符号位置
    slotSite: {
      type: String,
      default: 'suffix',
    },
  },
  components: {},
  data() {
    return {};
  },
  methods: {
    inputChange(val) {
      switch (this.type) {
        case '件':
          val = val.replace(/[^\d]/g, '');
          break;
        case '天':
        case '小时':
        case '$':
        case '￥':
          val = val.replace(/[^\d.]/g, ''); // 只能输入数字和.
          val = val.replace(/^\./g, ''); //第一个字符不能是.
          val = val.replace(/\.{2,}/g, '.'); // 不能连续输入.
          val = val.replace(/(\.\d+)\./g, '$1'); // .后面不能再输入.
          val = val.replace(/^0+(\d)/, '$1'); // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
          val = val.replace(/(\d{15})\d*/, '$1'); // 最多保留15位整数
          val = val.replace(/(\.\d{2})\d*/, '$1'); // 最多保留2位小数
          break;
        case '%':
          val = val.replace(/[^\d.]/g, ''); // 只能输入数字和.
          val = val.replace(/^\./g, ''); //第一个字符不能是.
          val = val.replace(/\.{2,}/g, '.'); // 不能连续输入.
          val = val.replace(/(\.\d+)\./g, '$1'); // .后面不能再输入.
          val = val.replace(/^0+(\d)/, '$1'); // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
          val = val.replace(/(\d{3})\d*/, '$1'); // 最多保留3位整数
          val = val.replace(/(\.\d{2})\d*/, '$1'); // 最多保留2位小数

          break;
      }

      this.$emit('input', val);
    },
  },
};
</script>
<style scoped lang="scss">
.input-model {
  .searchicon {
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #4d4d4d;
  }

  &::v-deep .el-input__suffix {
    position: absolute;
    height: 100%;
    right: 0px;
    top: 50%;
    color: #808080;
    transition: all 0.3s;
    pointer-events: none;
    width: fit-content;
    transform: translateY(-50%);
    line-height: 40px;
    padding-right: 16px;
  }
  &::v-deep .el-input__prefix {
    position: absolute;
    height: 100%;
    left: 0px;
    top: 50%;
    color: #808080;
    transition: all 0.3s;
    pointer-events: none;
    width: 30px;
    transform: translateY(-50%);
    line-height: 40px;
  }
}
</style>
