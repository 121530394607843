/**
 * axios 实例对象的声明和导出
 * @description POST 和 GET 方法的传参数据字段统一为 data，不再区分；但兼容 params 字段
 * @example
 * import request from '@/utils/request'
 * function someApi(data){
 *   return request({
 *      url: '/api/abc',
 *      method: 'post', // 'get'
 *      data: data
 *   })
 * }
 */

import axios from 'axios';
import qs from 'qs';
import { Message, Loading } from 'element-ui';
import { logout, isLogin, getXimuToken } from '@/utils/service';
import { getBaseURL } from '@/utils/api_modules.js';
import { getErrMsgByHttpCode, getErrMsgByApiCode } from './error';
import PACKAGE_JSON from '@/../package.json';

let messageInstance = null;
const resetMessage = options => {
  if (messageInstance) {
    messageInstance.close();
  }
  messageInstance = Message(options);
};
['error', 'success', 'info', 'warning'].forEach(type => {
  resetMessage[type] = options => {
    if (typeof options === 'string') {
      options = {
        message: options,
      };
    }
    options.type = type;
    return resetMessage(options);
  };
});

const service = axios.create({
  baseURL: process.env.VUE_APP_API,
  timeout: 2 * 1000 * 60,
});

let loading;
// 总开关
let closeLoading = false;
//当前正在请求的数量
let needLoadingRequestCount = 0;
// 显示loading
function showLoading(target, closeLoading = false) {
  // 后面这个判断很重要，因为关闭时加了抖动，此时loading对象可能还存在，
  // 但needLoadingRequestCount已经变成0.避免这种情况下会重新创建个loading
  if (!closeLoading) {
    if (needLoadingRequestCount === 0 && !loading) {
      loading = Loading.service({
        lock: true,
        // text: 'Loading...',
        background: 'rgba(255, 255, 255, 0.5)',
        target: target || 'body',
      });
    }
    needLoadingRequestCount++;
  }
}
// 防抖：将 300ms 间隔内的关闭 loading 便合并为一次。防止连续请求时， loading闪烁的问题。
let toHideLoading = _.debounce(() => {
  if (loading) {
    loading.close();
    loading = null;
  }
}, 300);
// 隐藏loading
function hideLoading() {
  needLoadingRequestCount--;
  needLoadingRequestCount = Math.max(needLoadingRequestCount, 0); //做个保护
  if (needLoadingRequestCount === 0) {
    //关闭loading
    toHideLoading();
  }
}

service.interceptors.request.use(
  config => {
    config.baseURL = getBaseURL(config.apiModule);

    const token = getXimuToken();
    if (config.method == 'post') {
      if (!config.data) config.data = {};
      config.headers = {
        'Content-Type':
          config.dataType === 'json'
            ? 'application/json'
            : 'application/x-www-form-urlencoded',
      };
      if (token) {
        config.headers.token = token;
      }
      // 是否隐藏警告弹窗
      if (config.data?.MUTE_WARNING) {
        config.headers.mute_msg_warning = config.data.MUTE_WARNING;
        delete config.data.MUTE_WARNING;
      }
      // 中英文切换特别处理
      if (config.data?.API_LANG_TYPE) {
        config.headers.language = config.data.API_LANG_TYPE?.toUpperCase();
        delete config.data.API_LANG_TYPE;
      }
      if (config.dataType === 'json') {
        config.data = JSON.stringify(config.data);
      } else if (config.dataType !== 'form-data') {
        config.data = qs.stringify(config.data);
      }
    } else {
      if (!config.params) config.params = {};
      // GET
      config.headers = {
        'Content-Type': 'application/json; charset=utf-8',
      };
      if (token) {
        config.headers.token = token;
      }
      // 是否隐藏警告弹窗
      if (config.params?.MUTE_WARNING) {
        config.headers.mute_msg_warning = config.params.MUTE_WARNING;
        delete config.params.MUTE_WARNING;
      }
      // 中英文切换特别处理
      if (config.params.API_LANG_TYPE) {
        config.headers.language = config.params.API_LANG_TYPE?.toUpperCase();
        delete config.params.API_LANG_TYPE;
      }
    }
    // 请求头添加版本号
    if (PACKAGE_JSON.version) {
      config.headers.VERSION = PACKAGE_JSON.version;
    }
    // 请求头增加来源
    config.headers.PLATFORM = 'PC';
    // 判断当前请求是否设置了不显示Loading
    if (!closeLoading) {
      // 如果有控制器
      if (config.controller && config.controller.closeLoading) {
        showLoading(
          config.headers.loadingTarget,
          config.controller.closeLoading
        );
      } else {
        // 如果没控制器
        showLoading(config.headers.loadingTarget);
      }
      return config;
    }
    return config;
  },
  error => {
    Promise.reject(error);
  }
);

service.interceptors.response.use(
  response => {
    let hasLogin = isLogin();
    let resCode = response?.data?.errcode || '';

    if (hasLogin) {
      // 检查登录状态是否有效
      let message = getErrMsgByApiCode('' + resCode);
      if (message) {
        alert(message);
        logout();
      }
    }
    if (resCode == -106 || resCode == -107) {
      // 账号被冻结，或者限制关闭，跳转到登录页
      logout();
    }
    // 接收到响应数据并成功后的一些共有的处理，关闭loading等
    // 不管成不成功关就完事儿了
    hideLoading();
    let muteWarning = response?.config?.headers?.mute_msg_warning;
    if (resCode != 0) {
      let popWarning =
        !getErrMsgByApiCode('' + resCode) &&
        response?.data?.msg &&
        muteWarning != '1';
      if (popWarning) {
        // 接口返回异常错误码（!= 0）时，统一在这里报错
        resetMessage.warning(response.data.msg);
      }
    }
    return response.data;
  },
  error => {
    hideLoading();
    let isTimeout = false;
    let errorMessage = '';
    if (error?.response) {
      // 请求成功发出，服务器返回错误响应码
      errorMessage = getErrMsgByHttpCode(error.response.status);
    } else if (JSON.stringify(error).includes('timeout')) {
      // 请求成功发出，但服务器无响应
      isTimeout = true;
      errorMessage = '连接超时，请刷新当前页面或检查您的网络';
    } else {
      errorMessage = '连接服务器失败';
    }
    error.message = errorMessage;
    if (errorMessage) {
      // HTTP 异常状态码，统一在这里报错
      resetMessage.error(errorMessage);
    }
    return Promise.reject(error?.response ? error.response : error);
  }
);

export default service;
