/**
 * 订单模块
 * - 交易
 */

const orderListComponent = () => import('@/pages/order/orderList');
const orderUnpaidComponent = () => import('@/pages/order/orderUnpaidList');
const shippedListComponent = () => import('@/pages/order/shippedList');
const receivedListComponent = () => import('@/pages/order/receivedList');
const afterSalesListComponent = () => import('@/pages/order/afterSalesList');
const orderDetailComponent = () => import('@/pages/order/orderDetails');
const paymentProcessingComponent = () =>
  import('@/pages/order/paymentProcessing');
const detailsDisputeComponent = () =>
  import('@/pages/order/disputes/details/index.vue'); // 纠纷详情
const afterSalesInfoComponent = () => import('@/pages/order/afterSalesInfo');
const orderDeliveryNoticeComponent = () =>
  import('@/pages/order/orderDeliveryNotice');
const orderOnlineDomesticComponent = () =>
  import('@/pages/order/orderOnlineDomestic');
const printLabelComponent = () => import('@/pages/order/component/printLabel');
const deliveryCompontent = () => import('@/pages/order/delivery/index');

// 平台直发物流相关
const InternationalOrder = () => import('@/pages/order/international-order/index.vue');//国际小包订单
const BatchDownloadWaybill = () => import('@/pages/order/international-order/batch-download-waybill/index.vue');//批量打印/下载面单
const MergeShipments = () => import('@/pages/order/international-order/merge-shipments/index.vue');//合并包裹发货
const OnlineShipping = () => import('@/pages/order/international-order/online-shipping/index.vue');//线上发货


const orderRoutes = [
  {
    path: '/orderList:currency?',
    name: 'orderList',
    meta: {
      title: '订单管理',
      path: '/orderList',
      type: '0',
      navIndex: 'order',
      needsToken: true,
      needsAuth: true,
    },
    component: orderListComponent,
  },
  {
    path: '/orderUnpaidList',
    name: 'orderUnpaidList',
    meta: {
      title: '待支付订单',
      path: '/orderUnpaidList',
      type: '2',
      navIndex: 'order',
      needsToken: true,
    },
    component: orderUnpaidComponent,
  },
  {
    path: '/shippedList',
    name: 'shippedList',
    meta: {
      title: '待发货订单',
      path: '/shippedList',
      type: '3',
      navIndex: 'order',
      needsToken: true,
    },
    component: shippedListComponent,
  },
  {
    path: '/receivedList',
    name: 'receivedList',
    meta: {
      title: '待收货订单',
      path: '/receivedList',
      type: '6',
      navIndex: 'order',
      needsToken: true,
    },
    component: receivedListComponent,
  },
  {
    path: '/afterSalesList',
    name: 'afterSalesList',
    meta: {
      title: '退款/售后订单',
      path: '/afterSalesList',
      type: '4',
      navIndex: 'order',
      needsToken: true,
    },
    component: afterSalesListComponent,
  },
  {
    path: '/orderDetails',
    name: 'orderDetails',
    meta: {
      title: '订单详情',
      path: '/orderDetails',
      type: '4',
      navIndex: 'order',
      needsToken: true,
    },
    component: orderDetailComponent,
  },
  {
    path: '/paymentProcessing',
    name: 'paymentProcessing',
    meta: {
      title: '支付处理中',
      path: '/paymentProcessing',
      navIndex: 'order',
      needsToken: true,
    },
    component: paymentProcessingComponent,
  },
  // 纠纷详情
  {
    path: '/detailsDispute/:currency?',
    name: 'detailsDispute',
    meta: {
      title: '纠纷详情',
      path: '/detailsDispute',
      navIndex: 'order',
      needsToken: true,
    },
    component: detailsDisputeComponent,
  },

  {
    path: '/afterSalesInfo',
    name: 'afterSalesInfo',
    meta: {
      title: '售后详情',
      path: '/afterSalesInfo',
      type: '4',
      navIndex: 'order',
      needsToken: true,
    },
    component: afterSalesInfoComponent,
  },
  {
    path: '/orderDeliveryNotice',
    name: 'order',
    meta: {
      title: '发货通知',
      path: '/orderDeliveryNotice',
      type: '4',
      navIndex: 'order',
      needsToken: true,
    },
    component: orderDeliveryNoticeComponent,
  },
  {
    path: '/orderOnlineDomestic',
    name: 'orderOnlineDomestic',
    meta: {
      title: '国内线上发货',
      path: '/orderOnlineDomestic',
      type: '4',
      navIndex: 'order',
      needsToken: true,
    },
    component: orderOnlineDomesticComponent,
  },
  {
    path: '/orderOnlineInternational',
    name: 'orderOnlineInternational',
    meta: {
      title: '国际线上发货',
      path: '/orderOnlineInternational',
      type: '4',
      navIndex: 'order',
      needsToken: true,
    },
    component: orderOnlineDomesticComponent,
  },
  //打印发货标签
  {
    path: '/printLabel',
    name: 'printLabel',
    meta: {
      title: '打印发货标签',
      path: '/printLabel',
      type: '4',
      navIndex: 'order',
      needsToken: true,
    },
    component: printLabelComponent,
  },
  {
    path: '/evaluations',
    name: 'evaluations',
    meta: {
      title: '评价管理',
      navIndex: 'order',
      path: '/evaluations',
      needsToken: true,
    },
    component: () => import('@/pages/order/evaluations/evaluations'),
  },
  {
    path: '/delivery',
    name: 'orderDeliver',
    meta: {
      title: '发货',
      path: '/delivery',
      type: '4',
      navIndex: 'order',
      needsToken: true,
    },
    component: deliveryCompontent,
  },
  // 平台物流相关
  {
    path: '/international_order',
    name: 'internationalorder',
    meta: {
      title: '国际小包订单',
      navIndex: 'order',
      path: '/international_order',
      needsToken: true,
    },
    component: InternationalOrder,
  },
  {
    path: '/batchdownload_waybill/:timestamp',
    name: 'batchdownloadwaybill',
    meta: {
      title: '批量下载面单',
      navIndex: 'order',
      needsToken: true,
    },
    component: BatchDownloadWaybill,
  },
  {
    path: '/mergeshipments/:timestamp',
    name: 'mergeshipments',
    meta: {
      title: '合并包裹发货',
      navIndex: 'order',
      needsToken: true,
    },
    component: MergeShipments,
  },
  {
    path: '/online_shipping/:typeNum?/:orders_id?',
    name: 'onlineshipping',
    meta: {
      title: '线上发货',
      navIndex: 'order',
      needsToken: true,
    },
    component: OnlineShipping,
  },

];

export default orderRoutes;
