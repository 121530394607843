<template>
  <div class="multiple-choice" :style="{ width: width }">
    <el-select
      slot="reference"
      clearable
      v-model="selectData"
      placeholder="请选择"
      multiple
      @visible-change="changeSelectShow"
      popper-class="xm-multiple-choice-box-style"
      :style="{ width: width }"
      @remove-tag="closeFunc"
      @clear="closeFunc"
    >
      <div
        class="option-model"
        :style="{ width: optionWidth ? optionWidth : width }"
      >
        <el-option style="display: none" value=""></el-option>
        <el-checkbox-group v-model="selectData">
          <div v-for="item in optionsData" :key="item.value" class="item-model">
            <el-option :label="item.label" :value="item.value">
              <el-checkbox
                :label="item.value"
                @change="checkOption(item, $event)"
              >
                {{ item.label }}
              </el-checkbox>
            </el-option>
          </div>
        </el-checkbox-group>
      </div>
    </el-select>
    <div class="tags" v-show="selectData.length !== 0 && selectShow">
      <div class="arrow"></div>
      <div class="tags-item" v-for="(item, index) in selectData" :key="item">
        <span>{{ getLabel(item) }}</span>
        <span
          ><i
            class="el-tag__close el-icon-close"
            @click="deselect(index, true)"
          ></i
        ></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchIcon: require('@/assets/images/sousuo.svg'), // 搜索图标
      selectData: [], //
      searchTarget: '',
      selectShow: true,
      selectWidth: '1000px',
    };
  },
  props: {
    // 选项data
    optionsData: {
      type: Array,
      default: () => [],
    },
    // 是否有搜索功能
    isSearch: {
      type: Boolean,
      default: true,
    },
    width: {
      type: String,
      default: '252px',
    },
    optionWidth: {
      type: String,
      default: '',
    },
    echoSelectData: {
      type: Array,
      default: () => [],
    },
    keyName: {
      type: String,
      default: 'keyName',
    },
    selectValue: {
      type: Array,
      default: () => [],
    },
    languageType: {
      type: String,
      default: '2',
    },
  },
  watch: {
    echoSelectData: {
      handler(newValue) {
        this.selectData = newValue.filter(item => item);
      },
      immediate: true,
    },
    selectValue: {
      handler(val) {
        this.selectData = val;
      },
      immediate: true,
    },
  },
  methods: {
    //根据value值获取对应label值
    getLabel(value) {
      let arr = this.optionsData.filter(item => item.value === value);
      if (arr.length) {
        return arr[0].label;
      }
      return '';
    },
    //取消选择
    deselect(index, flag) {
      this.selectData.splice(index, 1);
      if (flag) {
        this.closeFunc();
      }
    },
    //点击复选框也能选中
    checkOption(item, checkbox) {
      console.log(checkbox, 'checkbox', item.value);

      if (
        (item.value == '无' || item.value == 'none') &&
        this.selectData.length > 0
      ) {
        // 取消选中
        if (checkbox) {
        } else {
          // 选中
          this.selectData = [];
        }
      } else {
        let index = this.selectData.indexOf('无');
        let index1 = this.selectData.indexOf('none');
        if (
          (this.languageType == 2 && index > -1) ||
          (this.languageType == 3 && index1 > -1)
        ) {
          let spliceIndex = this.languageType == 2 ? index : index1;
          this.selectData.splice(spliceIndex, 1);
        }
      }
      this.searchTarget = '';
      let i = this.selectData.indexOf(item.value);

      if (i === -1) {
        this.selectData.push(item.value);
      } else {
        this.selectData.splice(i, 1);
      }
      console.log(this.selectData, 'this.selectData');

      this.$emit('change', this.selectData);
    },
    getSelect(data) {
      let itemValue = data[data.length - 1];
      if (
        (itemValue == '无' || itemValue == 'none') &&
        this.selectData.length > 0
      ) {
        this.selectData = [];
      } else {
        let index = this.selectData.indexOf('无');
        let index1 = this.selectData.indexOf('none');
        if (
          (this.languageType == 2 && index > -1) ||
          (this.languageType == 3 && index1 > -1)
        ) {
          let spliceIndex = this.languageType == 2 ? index : index1;
          this.selectData.splice(spliceIndex, 1);
        }
      }
      this.searchTarget = '';
      if (this.isRadio && this.selectData?.length > 1) {
        this.selectData.splice(0, 1);
      }
      this.$emit('change', this.selectData);
    },
    changeSelectShow(sign) {
      if (!sign) this.closeFunc();
      this.selectShow = !sign;
    },
    closeFunc() {
      this.$emit('closeEvent', this.selectData);
    },
  },
};
</script>

<style lang="scss" scoped>
.multiple-choice {
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  ::v-deep .el-input__inner {
    border-radius: 0px;
  }

  .xm-multiple-choice-box-style {
    min-width: 0 !important;
    .el-select-dropdown__item.selected {
      background: #ffffff !important;
    }
    .el-select-dropdown__list {
      padding: 0;
    }
    .popper__arrow {
      display: none;
    }
    .el-scrollbar__wrap {
      overflow-x: hidden;
    }
    .el-scrollbar {
      height: fit-content;
    }
    .search-input {
      padding: 10px;
    }

    .search-input .el-input__inner {
      background: #f7f7f7;
      border-color: #e6e6e6;
    }

    .search-input .el-input__prefix {
      top: 12px;
      left: 13px;
    }

    .option-model {
      height: 252px;
      overflow-y: auto;
      overflow-x: hidden;
      padding: 12px 0px;
    }

    .label-box {
      max-height: 95px;
      overflow: auto;
      min-height: 86px;

      &::-webkit-scrollbar {
        width: 0 !important;
        height: 0;
      }
    }

    .el-select-dropdown__wrap {
      max-height: 1000px;
    }
  }
}

.multiple-choice:hover .tags {
  // display: block;
  visibility: visible;
  opacity: 1;
}

.tabs-box {
  width: fit-content;
  // margin-top: 10px;
  background: #ffffff;
  height: 40px;
  padding: 8px 10px;
}
.tags {
  font-size: 12px;
  font-weight: 400;
  width: fit-content;
  margin-top: 10px;
  background: #ffffff;
  // height: 40px;
  white-space: nowrap;
  padding: 0px 10px;
  // display: none;
  visibility: hidden;
  opacity: 0;
  transition: 0.25s ease-in;
  position: absolute;
  z-index: 1000;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);

  .arrow {
    width: 0;
    height: 0;
    border: 6px solid transparent;
    border-bottom: 6px solid #ffffff;
    // background: black;
    position: absolute;
    top: -12px;
    left: 16px;
  }

  .tags-item {
    display: inline-block;
    padding: 0px 10px;
    background: #f4f4f5;
    margin-right: 10px;
    color: #909399;
    line-height: 23px;
    i {
      text-align: center;
      position: relative;
      cursor: pointer;
      font-size: 12px;
      vertical-align: middle;
      top: -1px;
      right: -5px;
    }

    &:last-of-type {
      margin-right: 0px;
    }
  }
}
.multiple-choice ::v-deep .el-select__tags {
  flex-wrap: nowrap;
  overflow: hidden;
}
</style>
<style lang="scss">
// 因为交互冲突，所以通过改变节点样式来达到交互的正常
.xm-multiple-choice-box-style {
  .el-select-dropdown__item {
    padding: 0 !important;
    .el-checkbox {
      width: -webkit-fill-available;
      padding: 0 20px;
    }
  }
}
</style>
